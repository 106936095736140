/* src/components/AssetDetailEditor/TokenInteractorNew.module.css */

.interactorContainer {
    margin-top: 10px;
    background-color: #f9f9f9;
    border-radius: 8px;
    text-align: center;
    font-family: Arial, sans-serif;
    color: #333;
    font-size: 14px;
  }
  
  .buttonContainer {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  
  .unlockButton {
    background-color: #ff6a00; /* Orange color */
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .unlockButton:hover {
    background-color: #e65a00; /* Darker orange on hover */
  }
  
  .metaMaskIcon {
    width: 30px; /* Set the width of the icon */
    height: 30px; /* Set the height of the icon */
    margin-right: -15px; /* Space between the icon and the text */
    margin-top: 3px;
  }

  .unlockButtonText {
    font-size: 16px;
    font-weight: bold;
    padding-left: 25px;
  }
  .accountInfo {
    margin-top: 20px;
    text-align: center;
  }
  

/* .detailsTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.detailsTable td {
  padding: 10px;
  border: 1px solid #ddd;
}

.detailsTable td strong {
  font-weight: bold;
} */

.accountInfo {
  margin-top: 20px;
}

.buttonContainer {
  display: flex;
  align-items: center;
}

/* .buyButton {
  margin-top: 20px;
}
 */
/* .readOnlyInput, .tokenInput {
  flex: 1;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

.tokenInputContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
 */
.iconButton {
  background-color: #ccc;
  border: none;
  padding: 10px;
  cursor: pointer;
}

/* .tokenInput {
  width: 60px;
  text-align: center;
  margin: 0 5px;
  background-color: #f5f5f5;
} */

.orderDetails {
  margin-top: 20px;
}

.orderRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.orderRow span {
  display: inline-block;
}

.orderRow span:nth-child(1) {
  width: 40%; /* Adjust based on your content */
  text-align: left;
}

.orderRow span:nth-child(2) {
  width: 30%; /* Adjust based on your content */
  text-align: center; /* Centers the content of the second span */
}

.orderRow span:nth-child(3) {
  width: 30%; /* Adjust based on your content */
  text-align: right; /* Aligns the third span to the right */
}

.hashRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.hashRow span {
  display: inline-block;
}

.hashRow span:nth-child(1) {
  width: 60%; /* Adjust based on your content */
  text-align: left;
}

.hashRow span:nth-child(2) {
  width: 40%; /* Adjust based on your content */
  text-align: right; /* Centers the content of the second span */
}

.buyTokenButton {
  background-color: #6200ee;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
}  

.buyTokenButton:hover {
  background: #4a2472;
}

.buyTokenButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .interactorContainer {
    font-size: 11px;
  }

  .metaMaskIcon {
    width: 20px;
    height: 20px;
    margin-right: 0px;
    margin-top: 1px;
  }

  .unlockButtonText {
    font-size: 10px;
    padding-left: 0px;
  }

  .unlockButton {
    padding: 8px 16px;
    width: 65%;
  }
}