.container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .header h2 {
    margin: 0;
  }
  
  .filterContainer {
    display: flex;
    align-items: center;
  }
  
  .customSelect {
    position: relative;
    display: inline-block;
    margin-right: 10px;
  }
  
  .filter {
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    appearance: none;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    background-color: #fff;
    background-image: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjNjY2NjY2IiBoZWlnaHQ9IjEiIHdpZHRoPSIxIiB2aWV3Qm94PSIwIDAgMTAgNSI+PHBhdGggZD0iTTAgMGw1IDVNNyAwTDEyIDVNNSA0bDYtNiIvPjwvc3ZnPg=='); /* Custom arrow icon */
    background-repeat: no-repeat;
    background-position: right 10px top 50%;
  }
  
  .filter:focus {
    outline: none;
    border-color: #4CAF50; /* Green border on focus */
  }
  
  .search {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .timeline {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: 40px;
  }
  
  .timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 28px;
    height: 100%;
    width: 2px;
    background-color: #ddd;
  }
  
  .timelineItem {
    display: flex;
    align-items: flex-start;
    padding: 20px 0;
    border-bottom: 1px solid #e0e0e0;
    position: relative;
  }
  
  .timelineItem:last-child {
    border-bottom: none;
  }
  
  .iconContainer {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4CAF50; /* Green background */
    border-radius: 4px; /* Square border with rounded corners */
    position: absolute;
    left: -30px;
    z-index: 1;
  }
  
  .icon {
    font-size: 18px;
    color: #777; /* Grey icon color */
  }
  
  .details {
    flex: 1;
    padding-left: 50px; /* Adjusted to give space from the icon */
  }
  
  .details strong {
    display: block;
    margin-bottom: 5px;
  }
  
  .detailsBox {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 5px;
  }
  
  .noteBox {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .details p, .noteBox p {
    margin: 0;
    color: #555;
  }
  
  .reminderDetails {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
  }
  
  .priority {
    color: orange;
  }
  
  .time {
    font-size: 12px;
    color: #999;
    text-align: right;
  }
  
  .showMoreButton {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
  }
  
  .showMoreButton:hover {
    background-color: #45a049;
  }
  