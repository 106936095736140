.container {
  display: flex;
  flex-direction: column; /* Arrange items vertically */
  gap: 20px; /* Add gap between items */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #fff;
  padding: 20px;
}
  
  /* .investProcess {
    flex: 5;
  }
   */
