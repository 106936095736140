.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: linear-gradient(#45a049,#97BC62FF);
  }
  
  .loginBox {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    width: 400px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    text-align: center; /* Center align the content */
  }
  
  .logo {
    width: 200px; /* Adjust size as needed */
    margin-bottom: 20px;
  }
  
  .inputField {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    text-align: left; /* Align labels to the left */
  }
  
  .inputField label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .inputField input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .inputField input.invalid {
    border-color: red;
  }
  
  .errorMessage {
    color: red;
    margin-top: 5px;
  }
  
  .button {
    margin-top: 20px;
    padding: 10px 20px;
    background-image: linear-gradient(#45a049,#97BC62FF);;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #45a049;
  }
  
  .linksContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .link {
    color: #4CAF50;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .link:hover {
    color: #388E3C;
  }
  
  @media(max-width: 768px) {
    .loginBox {
        width: 80%;
        height: fit-content;
    }

    .logo {
        width: 150px;
        margin-bottom: 10px;
    }
  }