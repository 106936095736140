/* src/components/UserContent/UserContent.module.css */

.container {
    display: flex;
    /* height: 90vh; */
    width: 100%;
    overflow: hidden;
  }
  
  .leftContent {
    background-color: #4CAF50;
    padding-top: 20px;
    /* Add any styling if needed */
  
  }
  
  .topSpacer {
    padding-top: 10px;
    height: 30px;
  }
  
  .bottomSpacer {
    padding-top: 20px;
    height: 100px;
  }
  
  .menu {
    max-width: 250px; /* Fixed width */
    height: 80%; /* Full height of the screen */
    max-height: 100vh; /* Ensure the height does not exceed the viewport */
    background-color: #4CAF50;
    padding-top: 10px; /* Add space from the top */
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1); /* Only top shadow */
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden; /* Hide horizontal overflow */
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #388E3C #4CAF50; /* For Firefox */
  }
  
  /* Custom scrollbar styles for WebKit browsers (Chrome, Safari) */
  .menu::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }
  
  .menu::-webkit-scrollbar-thumb {
    background-color: #388E3C; /* Color of the scrollbar thumb */
    border-radius: 4px; /* Rounded corners */
  }
  
  .menu::-webkit-scrollbar-track {
    background-color: #4CAF50; /* Background color of the scrollbar track */
    border-radius: 4px; /* Rounded corners */
  }
  
  .menu ul {
    list-style: none;
    padding: 0;
  }
  
  .menu li {
    margin-bottom: 10px;
  }
  
  .menu button {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align text and icon to the start */
    width: 100%;
    padding: 10px;
    text-align: left;
    border: none;
    background: none;
    cursor: pointer;
    color: #fff;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .menu button:hover {
    background-color: #388E3C;
  }
  
  .menu button:focus {
    background-color: #388E3C;
    outline: none;
  }
  
  .activeMenuItem {
    background-color: #388E3C !important;
    color: #e0f7fa !important; /* Lighter font color for active menu item */
  }
  
  .withBorder {
    border-left: 2px solid #fff; /* Left border when submenu is active */
  }
  
  .icon {
    margin-right: 10px;
  }
  
  .subMenuToggle {
    padding-left: 10px;
    background: none;
    border: none;
    cursor: pointer;
    color: #fff;
  }
  
  .subMenu {
    margin-top: 3px;
    margin-left: 15px;
    list-style: none;
    padding-left: 0;
  }
  
  .subMenu li {
    margin-bottom: 5px;
  }
  
  .subMenu button {
    padding-left: 20px;
    color: #fff;
    transition: background-color 0.3s, color 0.3s;
    position: relative; /* Required for the arrow icon positioning */
  }
  
  .subMenu button:hover {
    background-color: #388E3C;
  }
  
  /* Style for the selected submenu item */
  .subMenu .activeSubMenuItem {
    color: #155724; /* Appealing text color */
    border-left: 2px solid #fff; /* White left border */
    font-weight: bold; /* Bold font for emphasis */
    background-color: #388E3C;
    /* Arrow icon */
    &::after {
      content: '➔'; /* Right arrow icon */
      position: absolute;
      right: 10px; /* Align the arrow to the right */
      color: #B2FF59; /* Same color as the text */
    }
  }
  
  .contentWrapper {
    flex: 1 1;
    display: grid;
    /* justify-content: center; */
    align-items: flex-start;
    background-color: var(--background-color);
    width: 100%;
    height: 100%;
    /* padding-bottom: 20px; */
    overflow-y: hidden;
    padding-top: 60px;
  }
  
  .content {
    width: 100%;
    max-width: 100%; /* Ensure the content doesn't exceed the container */
    height: 100%;
    background-color: #fff;
    border-radius: 8px 0 0 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto; /* Enable vertical scrolling if content overflows */
    padding: 20px;
  }
  
  .selectedContentWrapper {
    padding-top: 10px;
    margin-bottom: 100px;
  }
  
  @media (max-width: 768px) {
    .contentWrapper {
      background-color: #fff;
      align-items: end;
    }

    .content {
      box-shadow: none;
    }
  }