/* src/components/AssetDetailEditor/AssetValuation.module.css */

.valuationContainer {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.valuationHeading {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.tableContainer {
  width: 100%;
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 1rem;
  text-align: left;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.table th,
.table td {
  padding: 12px 15px;
  vertical-align: top; /* Align text to the top of the cell */
}

.table thead th {
  background-color: #e5eaf3;
  font-weight: 600;
  color: #333;
}

.table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table tbody tr:hover {
  background-color: #f1f1f1;
}

.badge {
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: center;
  display: inline-block;
}

.sortArrow {
  margin-left: 5px;
  font-size: 0.8rem;
  color: #555;
}

/* Link styling */
a {
  color: #555;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.docLink{
  color: blue;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .valuationContainer {
    padding: 15px;
    margin-top: 0px;
    background-color: #f9f9f9;
  }

  .valuationHeading {
    font-size: 14px;
    margin: 0px;
    padding: 0px 0px 20px 0px;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
    margin: 0px;
    font-size: 12px;
    text-align: left;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}
