/* Header.module.css */

.headerContainer {
    display: flex;
    align-items: center; /* Vertically center the items */
    padding: 10px 20px;
    background-color: white;
  }
  
  .selectorIcon {
    display: flex;
    align-items: center;
    color: #4CAF50;
    font-size: larger;
    margin-right: 12px; /* Space between logo and title */
  }
  
  .dot {
    width: 8px;
    height: 8px;
    background-color: #00bcd4; /* Example color for dots */
    border-radius: 50%;
    margin-right: 4px;
  }
  
  .title {
    font-weight: bold;
    font-size: 18px;
    color: #001f3f; /* Dark blue text */
  }

  .Seperator {
    font-weight: bold;
    font-size: 18px;
    color: #001f3f; /* Dark blue text */
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .icon {
    font-size: 24px;
    color: #0066FF; /* Example blue color for the icon */
    cursor: pointer;
    margin-left: auto; /* Push the icon to the far right */
  }
  
  @media (max-width: 768px){
    .title {
      font-size: 14px;
    }

    .selectorIcon {
      font-size: 14px;
    }
    
  }