.container {
    margin: 20px auto;
    font-family: Arial, sans-serif;
    color: #333;

    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  
  }
  
  .section {
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 20px;
    background: #fff;
  }
  
  .sectionContent {
    margin-bottom: 20px;
  }
  
  .section h3 {
    padding-top: 15px;
    padding-left: 15px;
    font-weight: bold;
    font-size: 16px;
    margin: 0;
  }
  
  .weeklyEstimates {
    padding: 10px;
    background: #f5faff;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .weeklyEstimates h4 {
    margin: 0 0 5px;
    font-size: 14px;
  }
  
  .weeklyEstimates p {
    margin: 0;
    font-size: 14px;
  }
  
  /* Order Summary Section */
  .orderSummary {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .orderHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .orderHeader h3 {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
  }
  
  .paymentPending {
    font-size: 14px;
    color: #ff9800;
    background-color: #fff3e0;
    padding: 5px 10px;
    border-radius: 5px;
  }
  

  .bookingSuccess {
    font-size: 14px;
    color: #4caf50;
    background-color: #e8f5e9;
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .successIcon {
    font-size: 16px;
  }

  .orderDetails {
    margin-top: 20px;
  }
  
  .orderRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .orderRow span {
    display: inline-block;
  }
  
  .orderRow span:nth-child(1) {
    width: 40%; /* Adjust based on your content */
  }
  
  .orderRow span:nth-child(2) {
    width: 30%; /* Adjust based on your content */
    text-align: center; /* Centers the content of the second span */
  }
  
  .orderRow span:nth-child(3) {
    width: 30%; /* Adjust based on your content */
    text-align: right; /* Aligns the third span to the right */
  }
  
  .preBook {
    font-weight: bold;
    margin-top: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #ddd; /* Adds horizontal line */
  }

  .selectSlices {
    margin-top: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 15px;
  }

  .orderTotal {
    font-weight: bold;
    margin-top: 15px;
    padding-top: 10px;
    border-top: 1px solid #ddd; /* Adds horizontal line */
  }
  
  .actionButtons {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }  
  .collectPaymentButton {
    background-color: #6200ee;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 14px;
    cursor: pointer;
  }  

  .collectPaymentButton:hover {
    background: #4a2472;
  }

  .collectPaymentButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .footerNote {
    font-size: 12px;
    color: #888;
    margin-top: 15px;
    padding-left: 20px;
  }
  
  .sliceSelector {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .sliceInput {
    width: 60px;
    text-align: center;
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .decrementButton,
  .incrementButton {
    width: 30px;
    height: 30px;
    font-size: 18px;
    font-weight: bold;
    color: #333;
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .decrementButton:hover,
  .incrementButton:hover {
    background: #ececec;
  }
  
  
  .container {
    margin: 20px auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .orderSummary {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .orderHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .paymentPending {
    font-size: 14px;
    color: #ff9800;
    background-color: #fff3e0;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .bookingSuccess {
    font-size: 14px;
    color: #4caf50;
    background-color: #e8f5e9;
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .notification {
    margin-bottom: 15px;
  }
  
  .successNotification {
    font-size: 14px;
    color: #4caf50;
    background-color: #e8f5e9;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .failureNotification {
    font-size: 14px;
    color: #f44336;
    background-color: #ffebee;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .notificationIcon {
    font-size: 16px;
  }
  
  .loadingSpinner {
    font-size: 16px;
  }

  