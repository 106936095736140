.container {
  display: flex;
  flex-direction: column; /* Change to column for vertical alignment */
  gap: 20px;
  padding: 20px;
  margin: 20px auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #fff;
}

.investProcess {
  flex: 1; /* Adjust flex properties for equal height distribution */
}

.assetInvestStats {
  flex: 1;
}

.graphContainer {
  display: flex;
  flex-direction: column; /* Align graph and valuation vertically */
  gap: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .container {
    gap: 10px;
    padding: 0px;
    margin: 0px;
    box-shadow: none;
  }

  .graphContainer {
    gap: 0px;
    padding: 15px;
  }

  .graphHeader{
    font-size: 14px;
    margin: 0;
    padding: 0px 0px 20px 0px;
  }
}