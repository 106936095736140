.container {
    margin: 20px auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .heading {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table th,
  .table td {
    text-align: left;
    padding: 10px;
  }
  
  .table thead th {
    background-color: #f5f5f5;
    color: #333;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
  }
  
  .table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .table tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  .customerCell {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .initials {
    display: inline-flex;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    background-color: #007bff;
    color: #fff;
    font-weight: bold;
    border-radius: 50%;
    font-size: 14px;
  }
  
  .amount {
    color: #4caf50;
    font-weight: bold;
  }
  
  .status {
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    display: inline-block;
    text-align: center;
  }
  
  .status.purchased {
    color: #4caf50;
    background-color: #e8f5e9;
  }
  
  .status.pending {
    color: #ff9800;
    background-color: #fff3e0;
  }
  
  .status.cancel {
    color: #f44336;
    background-color: #ffebee;
  }
  
  .noDataMessage {
    text-align: center;
    font-size: 16px;
    color: #888;
    margin: 20px 0;
  }
  
  .noData {
    text-align: center;
    font-size: 14px;
    color: #888;
    margin-top: 20px;
  }
  

.marketplaceLink {
  /* color: #6200ee; */
  text-decoration: none;
  font-weight: bold;
}

.marketplaceLink:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .container {
    margin: 0px;
    padding: 10px;
  }

  .heading {
    font-size: 14px;
  }

  .noData {
    font-size: 12px;
    margin-top: 15px;
  }
}