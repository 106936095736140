/* src/components/FAQSection.module.css */
.faqContainer {
  background-color: #fff;
  padding: 20px;
  /* border-radius: 8px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 4rem 0px;
}

.faqTitle {
  text-align: left;
  color: #333;
  margin-bottom: 20px;
}

.faqItem {
  margin-bottom: 10px;
}

.faqQuestion {
  width: 100%;
  background: #f7f7f7;
  border: none;
  padding: 15px;
  text-align: left;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.faqQuestion:hover {
  background-color: #e2e2e2;
}

.faqAnswer {
  background-color: #f9f9f9;
  padding: 15px;
  margin-top: 5px;
  border-left: 3px solid #4caf50;
  border-radius: 5px;
}

.faqQuestion svg {
  margin-right: 10px;
}

@media(max-width: 768px) {
  .faqContainer {
    background-color: #fff;
    padding: 10px;
    border-radius: 0px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px 0px;
    font-size: 16px;
  }

  .faqQuestion {
    padding: 10px;
    font-size: 14px;
    display: flex;
    justify-content: start;
  }
}