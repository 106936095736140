/* MetricsCard.module.css */

.metricsContainer {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .metricCard {
    flex: 1;
    margin: 0 10px;
    padding: 10px;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    background-color: #f9fafb;
    text-align: left;
  }
  
  .metricCard:not(:last-child) {
    border-right: none;
  }
  
  .metricHeader {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #1f2937;
  }
  
  .metricIcon {
    margin-right: 8px;
    font-size: 20px;
    color: #6b7280;
  }
  
  .metricValue {
    margin: 10px 0;
    font-size: 32px;
    color: #111827;
  }
  
  .metricComparison {
    display: flex;
    align-items: center;
    color: #6b7280;
  }
  
  .comparisonBadge {
    display: inline-block;
    background-color: #e5e7eb;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
    margin-right: 8px;
    color: #1f2937;
  }
  

  .container {
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    width: 100%; /* Updated to 100% to ensure full width */
  }
  
  .header {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
  }
  
  .assetList {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .assetItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
  }
  
  .assetItem:last-child {
    border-bottom: none;
  }
  
  .name {
    font-weight: bold;
  }
  
  .price {
    color: #333;
  }
  
  .change {
    color: green;
    font-weight: bold;
  }
  
  .marketCap {
    color: #666;
    font-size: 0.8rem;
  }

  .top_2 {
    margin-top : 1rem
  }
  
  /* You may remove or comment out the .watch class if it's no longer used */
  /*.watch {
    cursor: pointer;
  }*/

  /* .metricIcon {
    color: #4caf50;
    margin-right: 10px;
  } */
  
  .change {
    font-weight: bold;
  }
  
  .noData {
    text-align: center;
    font-size: 14px;
    color: #888;
    margin-top: 20px;
  }
    
  @media(max-width: 768px) {
    .metricsContainer {
      padding: 0px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .metricCard {
      margin: 0px;
    }

    .metricHeader {
      font-size: 14px;
    }

    .noData {
      font-size: 12px;
    }
  }