/* src/components/ForgetPassword.module.css */

.forgetPasswordContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #45a049;
  }
  
  .forgetPasswordBox {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    width: 400px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .inputField {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  .inputField label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .inputField input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .inputField input.invalid {
    border-color: red;
  }
  
  .errorMessage {
    color: red;
    margin-top: 5px;
  }
  
  .button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #45a049;
  }
  
  .linksContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .link {
    color: #4CAF50;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .link:hover {
    color: #388E3C;
  }

  .disabledLink {
    color: #dbdfe4;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .disabledLink:hover {
    color: #dbdfe4;
  }
  