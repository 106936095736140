/* src/components/AssetDetailEditor/AssetFeatures.module.css */
.featuresContainer {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  .featuresHeading {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .featuresGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  
  .featureItem {
    display: flex;
    align-items: center;
  }
  
  .featureIcon {
    color: #4caf50;
    margin-right: 10px;
  }
  
  .featureText {
    font-size: 14px;
    color: #555;
  }
  
  @media (max-width: 768px) {
    .featuresContainer {
      padding: 15px;
      margin-top: 10px;
    }

    .featuresHeading {
      font-size: 14px;
    }

    .featureItem {
      font-size: 12px;
    }

    .featureText {
      font-size: 12px;
    }
  }