/* MetricsCard.module.css */

.metricsContainer {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .metricCard {
    flex: 1;
    margin: 0 10px;
    padding: 10px;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    background-color: #f9fafb;
    text-align: left;
  }
  
  .metricCard:not(:last-child) {
    border-right: none;
  }
  
  .metricHeader {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #1f2937;
  }
  
  .metricIcon {
    margin-right: 8px;
    font-size: 20px;
    color: #6b7280;
  }
  
  .metricValue {
    margin: 10px 0;
    font-size: 32px;
    color: #111827;
  }
  
  .metricComparison {
    display: flex;
    align-items: center;
    color: #6b7280;
  }
  
  .comparisonBadge {
    display: inline-block;
    background-color: #e5e7eb;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
    margin-right: 8px;
    color: #1f2937;
  }
  
  @media(max-width: 768px){
    .metricsContainer {
      padding: 0px;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .metricCard {
      margin: 0px;
      padding: 10px;
    }

    .metricHeader {
      margin-bottom: 10px;
      font-size: 14px;
    }
  }