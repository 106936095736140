/* src/components/Card.module.css */

.card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    margin: 10px; /* Reduced margin for better fitting */
    padding: 20px;
    text-align: center;
    /*width: calc(33.333%-20px);*/ /* 3 cards per row with some margin */
    box-sizing: border-box; /* Ensure padding and borders are included in the width */
    min-width: 15rem; 
  }
  
  .cardImage {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 220px; /* Fixed height */
    width: 100%; /* Ensure image fills the container */
  }
  
  .cardHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .cardHeaderIcons .icon {
    margin-right: 10px;
  }
  
  .rented {
    color: red;
    font-weight: bold;
  }
  
  .cardBody h2 {
    font-size: 1.2em;
    margin: 10px 0;
  }
  
  .price {
    color: green;
    font-size: 2em;
    margin: 10px 0;
  }
  
  .investors {
    color: gray;
    margin-bottom: 20px;
  }
  
  .investmentDetails {
    background: #f9f9f9;
    border-radius: 8px;
    padding: 10px;
  }
  
  .investmentDetail {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
  }
  
  .cardHeaderText {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9em;
    color: #555;
  }
  
  .icon {
    display: flex;
    align-items: center;
    margin: 0 5px;
  }
  
  .propertyId, .status, .location {
    margin-left: 5px;
  }
  
  @media (max-width: 768px) {
    .card {
      margin: 0px;
      padding: 15px;
      width: 100%;
    }

    .cardHeaderText {
      font-size: 12px;
    }

    .cardHeader {
      padding: 10px;
      margin-bottom: 0px;
    }

    .cardBody h2 {
      font-size: 16px;
    }

    .investmentDetail {
      font-size: 14px;
    }
  }