.container {
    width: 97%;
    padding: 20px;
    margin: 20px auto;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .container h2 {
    text-align: left;
  }
  
  .qrCode {
    display: block;
    margin: 10px auto; /* Center align QR code */
    width: 150px; /* Fixed size for QR code */
    height: 150px;
  }
  
  .secretKey {
    display: block;
    width: 80%;
    margin-top: 20px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .codeInput {
    display: block;
    width: 80%;
    margin-top: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 18px;
  }
  
  .verifyButton {
    display: block;
    width: 20%;
    padding: 10px;
    margin-top: 20px;
    margin-left: auto; /* Pushes the button to the right */
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .verifyButton:hover {
    background-color: #45a049;
  }

  .digits {
    padding-top: 20px;
    display: flex;
    justify-content: left;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .digitInput {
    font-size: 32px;
    width: 40px;
    height: 40px;
    text-align: center;
    border: 2px solid #ccc;
    border-radius: 5px;
  }
  
  .listItem {
    display: flex;
    align-items: center; /* Keeps items vertically centered */
    justify-content: space-between; /* Distributes space between items */
    gap: 20px; /* Space between text and QR code */
    width: 80%;
  }

  .listText {
    flex: 1; /* Allows the text block to take up the remaining space */
  }
  
  .qrCodeContainer {
    flex-shrink: 0; /* Prevents the QR code container from shrinking */
    padding: 10px; /* Optional: Adds padding around the QR code for better spacing */
  }

  .stepsListItem {
    padding: 20px;
  }

  .secretKeyInputContainer {
    position: relative;
    display: flex;
    align-items: center;
    width: 80%;
  }
  
  .secretKey {
    flex-grow: 1;
    padding-right: 30px; /* Make room for the icon */
  }
  
  .copyIcon {
    position: absolute;
    right: 10px;
    bottom: 10px;
    cursor: pointer;
    color: #4CAF50;
  }
  
  .copyIcon:hover {
    color: #45a049;
  }
  
  /* Notification Styles */
.success {
  background-color: #d4edda;
  color: #155724;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid #c3e6cb;
}

.error {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid #f5c6cb;
}
