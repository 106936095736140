/* src/components/AssetDetailViewer/AssetCarousel/AssetCarousel.module.css */

.carouselContainer {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }


.carousel {
    display: flex;
    justify-content: space-between;
    height: 300px; /* Adjust the height as needed */
  }
  
  .leftColumn {
    flex: 1;
    padding: 5px;
  }
  
  .rightColumn {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 5px;
  }
  
  .topRow {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }
  
  .bottomRow {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }
  
  .largeImage {
    width: 100%;
    height: 300px;
    padding: 5px;
    border-radius: 10px; /* Rounded corners */
    cursor: pointer;
  }
  
  .smallImage {
    width: 48%;
    height: 145px;
    padding: 5px;
    border-radius: 10px; /* Rounded corners */
    cursor: pointer;
  }
  
  @media (max-width: 768px) {
    .rightColumn {
      flex-direction: row;
    }
    .smallImage {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  