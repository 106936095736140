/* src/components/KYCMessage.module.css */
.kycContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%; /* Full width */
    margin: 20px 0; /* Adjust margins as needed */
  } 
  
  .message {
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .kycButton {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .kycButton:hover {
    background-color: #45a049;
  }
  
  @media (max-width: 768px) {
    .kycContainer {
      padding: 10px;
      margin: 10px 0;
    }

    .message {
      font-size: 12px;
      margin-bottom: 20px;
    }

    .kycButton {
      padding: 8px 10px;
      font-size: 12px;
    }
  }