* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
    /* background-image: linear-gradient(#45a049, #97BC62FF); */
    color: #333;
    /* display: flex;
    justify-content: center; */
    align-items: center;
    min-height: 100vh;
    /* padding: 20px; */
}
.daocontainer{
    display: grid;
    justify-content: center;
    text-align: center;
    font-size: x-large;
    row-gap: 19px;
}

.meetourTeam{
    background-color: #f4f4f4;
    color: #18cc5d;
    margin-top: 3rem;
    position: relative;
    display: inline-table;
    width: 100%;
    padding: 21px 38px;
    font-size: 1.5rem;
    font-weight: 700;
}

.dao_container{
    width: 100%;
    background-color: #f9f5f5;
    padding: 10px;
}
.daoinvestore{
    /* background: -webkit-linear-gradient(#18cc5d, #f9f5f5); */
    /* -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    font-size: 1.4rem;
    margin-top: 0rem;
    color: #18cc5d;
    font-weight: 700;


}
.coreTeam{
    display: flex;
    justify-content: center;
    margin: 17px 0px 0px;
    font-size: 1.4rem;
    color: #18cc5d;
    font-weight: 700;
    /* background: -webkit-linear-gradient(#18cc5d, #f9f5f5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;    */
}
.backButton{
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #4caf50; /* Green background */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    align-self: flex-end;
}
.logoContainer {
    display: flex;
    align-items: center;
  }
  
  .logo {
    height: 35px;
    margin-right: 20px;
  }

.teamcontainer {
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    overflow-x: auto;
    padding: 10px 18px;
    justify-content: center;
    border-radius: 10px;
    background-color: #f9f5f5
}
.partnercontainer{
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    overflow-x: auto;
    padding: 10px 18px;
    justify-content: center;
    border-radius: 10px;
    background-color: #f9f5f5
}
.teammember {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 250px;
    flex-shrink: 0;
    transition: transform 0.3s, box-shadow 0.3s;
}

.teammember:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.memberphoto {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.memberinfo {
    padding: 15px;
}

.membername {
    font-size: 1.2rem;
    font-weight: bold;
    color: #444;
    margin-bottom: 5px;
}

.memberrole {
    font-size: 1rem;
    color: #777;
    margin-bottom: 10px;
}

.memberdescription {
    font-size: 0.9rem;
    color: #555;
}


.partnerssection {
    text-align: center;
    padding: 50px 20px;
    background-color: #ffffff;
}

.partnerssection h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
}

.partnerscontainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.partneritem {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f1f1f1;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.partneritem:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.partneritem img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    margin-bottom: 10px;
}

.partneritem h3 {
    font-size: 1.2rem;
    color: #333;
    margin: 10px 0;
}

.partneritem a {
    text-decoration: none;
    color: #007bff;
    font-size: 0.9rem;
}

.partneritem a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {

    .center{
        display: flex;
        justify-content: center;
    }

    .teamcontainer {
        gap: 10px;
        padding: 10px;
        justify-content: start;
        border-radius: 10px;
    }
    
    .teammember {
        width: 150px;
        height: 220px;
        flex-shrink: 0;
    }

    .memberphoto {
        width: 50%;
        height: 75px;
        margin-top: 5px;
    }
    
    .memberinfo {
        padding: 15px;
    }
    
    .membername {
        font-size: 16px;
    }
    
    .memberrole {
        font-size: 14px;
        margin-bottom: 5px;
    }
    
    .memberdescription {
        font-size: 12px;
        margin-top: 10px;
    }

    .partnercontainer{
        gap: 0px;
        padding: 0px;
        justify-content: start;
    }

    .partnerscontainer {
        gap: 20px;
        padding: 10px;
    }

    .partneritem {
        padding: 15px;
    }

    .partneritem h3 {
        font-size: 16px;
    }

    .partneritem a {
        font-size: 12px;
    }

    .coreTeam{
        margin: 17px 0px 15px 0px;
    }

    .meetourTeam{
        background-color: transparent;
        padding: 21px 15px;
        font-size: 28px;
    }
}

@media (max-width: 600px) {
    .partnerssection h2 {
        font-size: 2rem;
    }

    .partneritem img {
        width: 80px;
        height: 80px;
    }
}