.container {
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;  
}

.policyDate {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 15px;
  }
  
.policySection {
    margin-bottom: 20px;
  }
  
  .policySection h2 {
    font-size: 1.5rem;
    color: #444;
    margin-bottom: 10px;
  }
  
  .policySection p, .policySection ul {
    margin-bottom: 10px;
  }
  
  .policySection ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .policySection li {
    margin-bottom: 5px;
  }
  

.headerContainer {
    display: flex;
    justify-content:center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 60px;
  }
  
  .header {
    margin-bottom: 30px;
  }
  
  .mainTitle {
    font-size: 3rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .subTitle {
    font-size: 1.25rem;
    color: #666;
  }
  