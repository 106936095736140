.featuredListings {
  text-align: center;
  padding: 20px;
  background-color: #f0f4f8;
}

.featuredListings h2 {
  font-size: 2em;
  margin-bottom: 0.5em;
}

.featuredListings p {
  margin-bottom: 2em;
  color: #555;
}

.carousel {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.listingCard {
  background: white;
  margin: 0 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.imageContainer {
  position: relative;
}

.imageContainer img {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: green;
  color: white;
  padding: 5px;
  border-radius: 5px;
  margin-right: 5px;
}

.listingInfo {
  padding: 10px;
}

.listingInfo h3 {
  margin: 10px 0;
}

.listingInfo p {
  margin: 5px 0;
}

.price {
  color: blue;
  font-weight: bold;
  font-size: 1.2em;
  padding-top: 3px;
}

@media (max-width: 768px){
  .featuredListings h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5em;
  }

  .featuredListings p {
    margin: 10px;
    font-size: 14px;
  }

  .featuredListings {
    padding: 10px;
  }

  .carousel {
    gap: 0px;
  }

  .listingCard {
    margin: 0 5px;
    width: 34%;
  }

  .listingInfo h3 {
    margin: 0px 0;
    font-size: 0.8rem;
    font-weight: bolder;
  }

  .badge {
    font-size: 11px;
  }
}
