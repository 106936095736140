.container {
    padding: 20px;
    max-width: 600px;
    background-color: #fff;
  }
  
  .container h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
    text-align: left;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .inputGroup {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .inputGroup input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .passwordHint {
    margin-top: 5px;
    font-size: 12px;
    padding-left: 20px;
    color: #333;
    display: flex;
    align-items: center;
  }
  
  .passwordHint span {
    margin-right: 5px;
    font-size: 14px;
    color: #555;
  }
  
  .buttonContainer {
    display: flex;
    justify-content: flex-end;
  }
  
  .saveButton {
    padding: 12px 20px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .saveButton:hover {
    background-color: #218838;
  }
  

/* Password requirements */
.passwordRequirements {
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
}

.requirement {
  display: flex;
  align-items: center;
  color: #555;
  margin-bottom: 5px;
  font-size: 14px;
}

.checkmark {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: rgb(124, 134, 142); /* Grey background for circle */
  color: white; /* Grey color for checkmark */
}

.valid {
  color: white;
  background-color: green; /* Green background for valid */
}

/* Notification Styles */
.success {
  background-color: #d4edda;
  color: #155724;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid #c3e6cb;
}

.error {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid #f5c6cb;
}

@media (max-width: 768px) {
  .container h2 {
    margin-bottom: 10px;
    font-size: 16px;
  }

  .inputGroup {
    margin-bottom: 10px;
  }

  .inputGroup input {
    padding: 10px;
    font-size: 12px;
  }

  .passwordRequirements {
    margin-top: 5px;
  }

  .requirement {
    font-size: 12px;
  }

  .buttonContainer {
    justify-content: center;
  }

  .saveButton {
    padding: 8px 10px;
    font-size: 12px;
  }
}