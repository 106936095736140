/* src/components/PropertyWallet/PropertyWallet.module.css */

.container {
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #e0e0e0; /* Added border for sections */
  }
  
  .container h2 {
    font-size: 18px;
    margin-bottom: 10px;
    text-align: left;
  }
  
  .container p {
    font-size: 16px;
    margin-bottom: 20px;
    color: #555;
  }
  
  .walletInfo {
    margin-top: 20px;
  }
  
  .address {
    font-size: 16px;
    color: #333;
    word-wrap: break-word;
  }
  
  .etherscanLink {
    color: #007bff;
    text-decoration: none;
  }
  
  .etherscanLink:hover {
    text-decoration: underline;
  }
  
  .generateButton {
    display: block;
    margin-top: 20px;
    margin-left: auto;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .generateButton:hover {
    background-color: #45a049;
  }
  
  .tokenBalances {
    margin-top: 20px;
  }
  
  .tokenBalances h3 {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .tokenBalances ul {
    list-style-type: none;
    padding: 0;
  }
  
  .tokenBalances ul li {
    font-size: 14px;
    color: #555;
  }
  
  /* Notification Styles */
  .success {
    background-color: #d4edda;
    color: #155724;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    border: 1px solid #c3e6cb;
  }
  
  .error {
    background-color: #f8d7da;
    color: #721c24;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    border: 1px solid #f5c6cb;
  }
  