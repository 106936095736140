/* General container styling */
.container {
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  /* Heading styles */
  .heading {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  /* Subheading styles */
  .subheading {
    font-size: 1.5em;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  /* List styling */
  .list {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  /* Table styling */
  .table {
    width: 100%;
    border: 1px solid #ddd;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .table th, .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .table th {
    background-color: #f4f4f4;
  }
  
  .headerContainer {
    display: flex;
    justify-content:center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 60px;
  }

  .header {
    margin-bottom: 30px;
  }
  
  .mainTitle {
    font-size: 3rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .subTitle {
    font-size: 1.25rem;
    color: #666;
  }