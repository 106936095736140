/* src/components/HorizontalTabs.module.css */
.horizontalTabs {
    font-family: Arial, sans-serif;
    background-color: #fff;
    border: 1px solid #ddd;
    /* border-radius: 5px; */
    overflow: hidden;
  }
  
  .tabHeader {
    display: flex;
    border-bottom: 1px solid #ddd;
  }
  
  .tabItem {
    cursor: pointer;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    transition: background-color 0.3s ease;
  }
  
  .tabItem.active {
    background-color: #4caf50;
  }
  
  .tabContent {
    padding: 20px;
  }
  
  @media (max-width: 768px) {
    .tabItem {
      padding: 4px 4px;
      font-size: 10px;
    }

    .tabContent {
      padding: 20px;
    }
  }