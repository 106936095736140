/* src/HomePage.module.css */
.homepageContainer {
    font-family: Arial, sans-serif;
  }
  
  .header {
    background-color: #4caf50;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    height: 50px; /* Reduced height */
  }
  
  .logoContainer {
    display: flex;
    align-items: center;
  }
  
  .logo {
    height: 30px; /* Reduced height */
    margin-right: 10px;
  }
  
  .headerRight {
    display: flex;
    align-items: center;
  }
  
  .headerLink, .signInLink {
    color: white;
    text-decoration: none;
    font-weight: bold;
    margin-left: 20px;
  }
  
  .carousel {
    margin: 20px auto;
    max-width: 800px;
  }
  
  .introSection {
    text-align: center;
    margin: 20px;
  }
  
  .tagline {
    margin-top: 10px;
    font-size: 1.2em;
    color: #4caf50;
  }
  
  .whyInvestSection {
    background-color: #fff;
    padding: 20px;
    margin: 20px;
    text-align: left;
  }
  
  .whyInvestSection h2 {
    text-align: left;
  }
  
  .whyInvestSection ul {
    list-style-type: disc;
    margin: 10px 0 0 20px;
  }
  
  .featuresSection {
    background-color: #f4f4f4;
    padding: 20px;
    text-align: center;
  }
  
  .features {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .feature {
    background-color: white;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 45%;
    max-width: 300px;
  }
  
  .ctaSection {
    text-align: center;
    padding: 20px;
  }
  
  .ctaSection button {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .ctaSection button:hover {
    background-color: #45a049;
  }
  
  .faqSection {
    background-color: #fff;
    padding: 20px;
    margin: 20px;
    text-align: left;
  }
  
  .faqSection h2 {
    text-align: left;
    color: #4caf50;
  }
  
  .faqSection ul {
    list-style: none;
    padding: 0;
  }
  
  .faqItem {
    margin: 10px 0;
  }
  
  .faqQuestion {
    background: none;
    border: none;
    color: inherit; /* Use the same color as other text */
    cursor: pointer;
    font-size: 1.1em;
    font-weight: bold;
    text-align: left;
    width: 100%;
    padding: 10px 0;
  }
  
  .faqAnswer {
    margin: 10px 0;
    padding: 10px;
    background: #8B4513; /* Brown background */
    border-radius: 5px;
  }
  
  .footer {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    position: relative;
  }
  
  .footerContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .footerLinks a {
    color: white;
    margin-left: 10px;
    text-decoration: none;
  }
  
  .footerLinks a:hover {
    text-decoration: underline;
  }
  
  .socialMedia a {
    color: white;
    margin-left: 10px;
    font-size: 1.5rem;
  }
  
  .socialMedia a:hover {
    color: #ddd;
  }
  
  @media (max-width: 768px){
    .featuresSection {
      padding: 10px;
    }

    .features {
      display: flex;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      width: 100%;
      flex-wrap:nowrap
    }

    .features::-webkit-scrollbar{
      display: none;
    }

    .feature {
      flex: 0 0 35%;
      scroll-snap-align: start;
    }

    .feature h3{
      margin-bottom: 15px;
    }
  }