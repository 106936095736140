/* src/components/AssetDetailEditor/AssetLocation.module.css */
.locationContainer {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  .locationHeading {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .locationDetails {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  
  .locationItem {
    display: flex;
    align-items: center;
  }
  
  .locationIcon {
    color: #4caf50;
    margin-right: 10px;
  }
  
  .locationText {
    font-size: 14px;
    color: #555;
  }
  
  @media (max-width: 768px) {
    .locationContainer {
      padding: 15px;
      margin-top: 10px;
    }

    .locationHeading {
      font-size: 14px;
    }

    .locationText {
      font-size: 11px;
    }
  }