.container {
  margin: 20px auto;
  font-family: Arial, sans-serif;
  color: #333;

  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}

/* .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    margin: -30px auto;
    width: 100%;
  } */
  
  .investProcess {
    flex: 4; /* 80% width */
  }
  
  .assetInvestStats {
    flex: 1; /* 20% width */
  }
  
  @media (max-width: 768px) {
    .container {
    margin: 0;
    padding: 15px;
    background-color: #f9f9f9;
    }
  }