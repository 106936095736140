/* src/components/AssetDetailEditor/ProgressIndicator.module.css */

.progressContainer {
  display: flex;
  justify-content:center;
  align-items: center;
  margin: 20px 0;
}

.progressStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.activeStep {
  color: orange; /* Processing step */
}

.completedStep {
  color: green; /* Completed step */
}

.stepName {
  margin-top: 8px;
  font-size: 14px;
}

.checkIcon {
  color: green;
  font-size: 24px;
}

.spinnerIcon {
  font-size: 24px;
}

.processingIcon {
  color: orange;
}

.idleStepLabel {
  color: #ccc; /* Color for idle steps */
}
