.bannerContainer {
  background: linear-gradient(90deg, rgba(77, 220, 52, 0.5) 0%, rgba(131,58,180,0.5) 100%);
  height: 200px;
  display: flex;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  padding-left: 20px;
}

.bannerContent {
  text-align: left;
  color: white;
  z-index: 2;
}

.bannerTitle {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 20px 0px;
}

.bannerButtons {
  display: flex;
  gap: 10px;
}

.discoverButton, .seeArtistsButton {
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  font-size: 1rem;
  cursor: pointer;
  outline: none;
}

.discoverButton {
  background-color: white;
  color: black;
  font-weight: bold;
}

.seeArtistsButton {
  background-color: transparent;
  border: 2px solid white;
  color: white;
  font-weight: bold;
}

.seeArtistsButton:hover {
  background-color: white;
  color: black;
  transition: 0.3s;
}

.discoverButton:hover {
  background-color: #eee;
  transition: 0.3s;
}

@media (max-width: 768px) {
  .bannerContainer {
    height: 130px;
  }

  .bannerTitle {
    font-size: 18px;
    margin: 15px 0px;
  }

  .seeArtistsButton {
    font-size: 14px;
  }
}