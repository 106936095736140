/* UserPage.module.css */
.container {
    display: flex;
    flex-direction: column;
    /*height: 800px; /* Set this to your desired fixed height */
  }
  
  .topBanner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  /*  height: 80px;  Set this to the height of your TopBanner */
    z-index: 1000; /* Ensure it stays on top of other content */
  }
  
  .content {
    background-color: #4CAF50;
    margin-top: 0px; /* Adjust this to match the height of the TopBanner */
    overflow-y: auto;
    /*height: calc(100% - 80px); /* Adjust this based on the TopBanner height */
    height: 100%;
  }
  
  .mainContent {
    margin-top: 0px; /* Optional, adjust if needed */
  }
  

  
@media only screen and (max-width: 600px) {
  .menu_body_mobile {
    /* margin-top: 60px;
    display: inline;
    padding: 2px */
    position: absolute;
    margin-top: 13px;
  }

  .left_menu_desktop {
    display: none;
  }

  .closeMenu {
    margin-left: 50%;
    position: fixed;
    margin-top: 15%;
  }
}

@media only screen and (min-width: 768px) {

  /* For mobile phones: */
  .menu_body_mobile {
    display: none;
  }
}