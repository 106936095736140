.container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    /* margin: 20px auto; */
    width: 100%;
  }
  
  .investProcess {
    flex: 3; /* 80% width */
  }
  
  .assetInvestStats {
    flex: 2; /* 20% width */
  }
  
  @media (max-width: 768px) {
    .container {
      display: flex;
      justify-content: start;
      flex-direction: column;
      gap: 0px;
      /* margin: 20px auto; */
      width: 100%;
    }
  }