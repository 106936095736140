/* src/components/AssetDetailEditor/AssetHeader.module.css */
.container {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f9f9f9;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  margin: 0;
  font-weight: bold;
}

.addressTitle {
  margin: 0;
  font-weight: bold;
  text-align: left;
}

.address {
  margin: 5px 0;
  color: #888;
  font-size: 14px; /* Adjusted font size */
}

.priceContainer {
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.forSale {
  display: block;
  color: #888;
  margin-bottom: 5px; /* Added margin */
}

.propertyId {
  margin: 0;
  padding-top: 3px;
  color: #888;
}

.subHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.favorites {
  text-decoration: none;
  color: #888;
}

.shareContainer {
  display: flex;
  align-items: center;
}

.icon {
  margin-left: 10px;
  color: #888;
}

@media (max-width: 768px){
  .container {
    padding: 10px;
    margin-top: 5px;
  }

  .addressTitle {
    font-size: 13px;
  }

  .forSale {
    display: flex
  }

  .favorites {
    font-size: 13px;
  }
}