/* src/components/UserDashboard/UserDashboard.module.css */

.banner {
    background-color: #ffeeba; /* Light yellow */
    border-left: 5px solid #ffeb3b; /* Yellow accent */
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 5px;
  }
  
  .banner h3 {
    margin: 0;
    font-size: 18px;
    color: #856404; /* Dark brown */
    padding-bottom: 5px;
  }
  
  .banner p {
    margin: 5px 0 0 0;
    font-size: 16px;
    color: #856404;
    line-height: 25px;
  }
  
  .banner a {
    color: #007bff;
    text-decoration: underline;
  }
  
  .banner a:hover {
    text-decoration: none;
  }
  
  .resendLink {
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .resendLink:hover {
    color: #0056b3;
  }

  @media(max-width: 768px) {
    .banner {
      padding: 10px;
    }
  
    .banner h3 {
      font-size: 16px;
    }
  
    .banner p {
      font-size: 12px;
      line-height: 20px;
    }
  }