/* src/components/Marketplace.module.css */

.marketplace {
  min-height: 73vh;
  padding: 20px;
  width: 100%;
}

.itemsContainer {
  /*max-height: 700px; /* Max height of items container */
  overflow-y: auto; /* Enable vertical scrolling */
}


.image {
  width: 100%; /* Ensure image fills the container */
  max-height: 200px; /* Limit image height */
}

.itemDetails {
  text-align: center;
}

.itemTitle {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
}

.assetTitle {
  font-size: 1em;
  font-weight: normal;
  color: #555;
  margin: 5px 0;
}

.assetCost, .numInvestors {
  font-size: 0.9em;
  margin: 5px 0;
}

.scrollButton {
  width: 80%;
  padding: 10px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  cursor: pointer;
  margin: 0 auto; /* Ensure the button is centered horizontally */
}

.scrollButtonContainer {
  display: flex;
  justify-content: center; /* Center the button horizontally */
  margin-top: 20px; /* Adjust the margin as needed */
  align-items: center;
}

.itemsGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns */
  gap: 20px; /* Gap between items */
  width: 100%; /* Ensure grid takes full width */
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px; /* Adjust the border radius as needed */
  padding: 10px;
  margin: 0; /* Margin removed, controlled by card margin */
  cursor: pointer;
  transition: all 0.3s ease;
}

.item:hover,
.item:focus {
  border-color: #4caf50; /* Change border color on hover or focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add shadow effect on hover or focus */
}

@media (max-width: 768px) {
  .itemsGrid {
    grid-template-columns: none;
  }

  .item {
    padding: 0px;
  }
}