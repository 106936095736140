.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 20px;
    background-color: #fffbea; /* Golden-themed background */
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .leftAligned {
    flex-direction: row; /* Default alignment (image on the left) */
  }
  
  .rightAligned {
    flex-direction: row-reverse; /* Reverse alignment (image on the right) */
  }
  
  .image {
    flex: 0 0 40%; /* Set the image width to 40% */
  }
  
  .image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .description {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .description p {
    margin: 0;
    line-height: 20px;
  }  
  
  .title {
    font-size: 24px;
    font-weight: bold;
    color: #c49102; /* Golden color for the title */
    margin-bottom: 10px;
    margin-top: -20px; /* Move the title a bit higher */
  }
  
  .extraDescription {
    margin: 10px 15px;
    line-height: 25px;
  }
  
  .buttonContainer {
    text-align: right; /* Align the button to the right */
  }
  
  .purchaseButton {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #c49102; /* Golden button color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .purchaseButton:hover {
    background-color: #a37a00; /* Darker golden on hover */
  }
  
  @media (max-width: 768px) {
    .rightAligned {
      flex-direction: column;
    }
  
    .container {
      padding: 15px;
    }
  
    .description p {
      line-height: 18px;
      font-size: 14px;
    }
  
    .extraDescription {
      line-height: 20px;
      font-size: 12px;
      margin-bottom: 0px;
    }
  
    .buttonContainer {
      text-align: center;
    }
  
    .purchaseButton {
      margin-top: 20px;
      padding: 10px 10px;
      font-size: 12px;
    }  
  }