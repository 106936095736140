.container {
    font-family: Arial, sans-serif;
    /*width: 250px; /* Reduced width */
    margin: 0 auto;
    margin-top: 20px;
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px; /* Added padding */
  }
  
  .title {
    font-size: 16px; /* Reduced font size */
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 15px; /* Reduced bottom margin */
    color: #333;
    text-align: left; /* Left-aligned title */
  }
  
  .chartContainer {
    position: relative;
    /*width: 80%; /* Reduced size of the chart */
    margin: 0 auto;
    margin-bottom: 15px; /* Reduced bottom margin */

    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .legend {
    margin-top: 10px;
    text-align: left;
    font-size: 12px; /* Reduced font size */
    padding: 30px;
  }
  
  .legendItem {
    display: flex;
    align-items: center;
    margin-bottom: 5px; /* Reduced spacing */
    gap: 5px; /* Add spacing between spans */
  }
  
  .colorBox {
    width: 12px; /* Reduced size */
    height: 12px; /* Reduced size */
    display: inline-block;
    border-radius: 4px;
  }
  
  .legendItem span {
    display: inline-block;
    text-align: left;
  }
  
  .legendItem span:nth-child(1) {
    width: 12px; /* Width of color box */
  }
  
  .legendItem span:nth-child(2) {
    width: 80px; /* Width for category labels like 'Sold' or 'Available' */
  }
  
  .legendItem span:nth-child(3) {
    width: 50px; /* Width for percentages */
  }
  
  .legendItem span:nth-child(4) {
    width: 40px; /* Width for absolute values */
    text-align: right; /* Align numbers to the right */
  }
  
  .amount {
    font-weight: bold;
    font-size: 12px; /* Reduced font size */
  }

  .sectionContentWrapper {
    margin-bottom: 20px;
    display: inline-block;
    text-align: left;
  }

  .sectionContent {
    padding: 10px;
    background: #f5faff;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .sectionContent h4 {
    margin: 0 0 5px;
    font-size: 14px;
  }
  
  .sectionContent p {
    margin: 0;
    font-size: 13px;
  }

  @media (max-width: 768px) {
    .container {
      margin-top: 10px;
      padding: 0px;
    }

    .title {
      font-size: 14px;
    }

    .chartContainer {
      margin: 0;
      margin-bottom: 15px;
      padding: 15px;
    }
  }

