/* src/components/AssetDetailEditor/AssetRentalIncomeDetails.module.css */

.assetIncomeDetailsContainer {

  margin: 20px auto;
  font-family: Arial, sans-serif;
  color: #333;

  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);


    /* width: 100%; */
    padding: 20px;
    /* background-color: #f9f9f9; */
  }
  
  .headerContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .rentalIncomeHeader {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    display: flex;
    align-items: center; /* Align items vertically */
    gap: 10px; /* Add space between title and Change link */
  }
  
  .navigationContainer {
    display: flex;
    align-items: center;
  }
  
  .getButton {
    border: none;
    padding: 3px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 4px;
  }
  .navButton {
    background-color: #e0e0e0;
    border: none;
    padding: 3px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 1.2em;
  }
  
  .navigationText {
    font-weight: bold;
  }
  
  .changeLink {
    font-size: 16px;
    color: #333; /* Normal font color */
    cursor: pointer;
  }
  
  .dropdownContainer {
    display: flex;
    gap: 10px;
  }
  
  .selectInput {
    padding: 8px;
    font-size: 16px;
  }
  
  .assetIncomeDetails {
    width: 80%;
    margin: auto;
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .rentalIncomeContent {
    display: flex;
    flex-direction: column;
  }
  
  .monthlyCostHeader {
    cursor: pointer;
    font-size: 18px;
    margin: 30px 0 10px 0;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f0f0f0;
  }
  
  .monthlyCostTotal {
    font-weight: normal;
  }
  
  .collapseIcon {
    font-size: 16px;
    padding-left: 5px;
    margin-left: 10px;
  }
  
  .monthlyCostContent {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  
  .row {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .row:last-child {
    border-bottom: none;
  }
  
  .key {
    color: #333;
    flex: 1;
  }
  
  .value {
    color: #666;
    flex: 1;
    text-align: right;
  }
  
  .bold {
    font-weight: bold;
  }  

  .monthlyCostHeader {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.monthlyCostTotal {
    font-weight: bold;
}

/* New styles */
.header {
    text-align: center;
    margin-bottom: 20px;
}

.header h2 {
    font-size: 1.5rem;
    color: #333;
}

.dotsContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.dot {
    width: 10px;
    height: 10px;
    background-color: #ccc;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.dot.activeDot {
    background-color: #4caf50;
}  


@media (max-width: 768px) {
  .assetIncomeDetailsContainer {
    margin: 0px;
    padding: 15px;
    }

    .header h2 {
      font-size: 16px;
    }

    .header {
      margin-bottom: 10px;
    }

    .dotsContainer {
      margin-bottom: 15px;
    }

    .dot {
      width: 7px;
      height: 7px;
    }

    .assetIncomeDetails {
      width: 90%;
      margin: 0px;
      padding: 10px;
      border-radius: 10px;
    }

    .bold {
      font-size: 14px;
    }

    .row {
      font-size: 12px;
    }

    .monthlyCostHeader {
      font-size: 12px;
      margin: 7px 0px
    }

    .collapseIcon {
      font-size: 12px;
      margin-left: 10px;
      padding-left: 0px;
    }

    .monthlyCostContent {
      padding: 0px;
      margin-bottom: 0px;
    }
}