.container {
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    width: 100%; /* Updated to 100% to ensure full width */
  }
  
  .header {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
  }
  
  .assetList {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .assetItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
  }
  
  .assetItem:last-child {
    border-bottom: none;
  }
  
  .name {
    font-weight: bold;
  }
  
  .price {
    color: #333;
  }
  
  .change {
    color: green;
    font-weight: bold;
  }
  
  .marketCap {
    color: #666;
    font-size: 0.8rem;
  }
  
  /* You may remove or comment out the .watch class if it's no longer used */
  /*.watch {
    cursor: pointer;
  }*/
  