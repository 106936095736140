/* src/InvestInDubai.module.css */
.investSection {
    background-color: #f4f4f4;
    padding: 40px;
    text-align: center;
  }
  
  .investHeading {
    color: #4caf50;
    margin-bottom: 30px;
    font-size: 28px;
  }
  
  .cardsContainer {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .card {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    padding: 20px;
    margin: 10px;
    width: 200px;
    text-align: center;
    transition: transform 0.3s ease-in-out;
  }
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .icon {
    font-size: 40px;
    color: #4caf50;
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    .cardsContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .card {
      width: 44%;
      height: 238px;
    }

    .investHeading {
      margin-bottom: 10px;
      font-size: 1.5rem;
    }

    .investSection {
      padding: 10px;
      font-size: 14px;
    }

    .card h3{
      margin-bottom: 10px;
    }
  }
  