/* src/components/AssetDetailEditor/AssetBlockchain.module.css */
.blockchainContainer {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  .blockchainHeading {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .blockchainGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  
  .blockchainItem {
    display: flex;
    align-items: center;
  }
  
  .blockchainIcon {
    color: #4caf50;
    margin-right: 10px;
  }
  
  .blockchainText {
    font-size: 14px;
    color: #555;
  }
  
  .blockchainValue {
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .blockchainContainer {
      padding: 15px;
      margin-top: 10px;
    }

    .blockchainHeading {
      font-size: 14px;
      margin-bottom: 15px;
    }

    .blockchainGrid {
      gap: 20px;
      grid-template-columns: repeat(1, 1fr);
    }

    .blockchainText {
      font-size: 11px;
    }
  }