/* src/components/AssetDetailEditor/AssetBasicDetails.module.css */
.basicDetailsContainer {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  .basicDetailsHeading {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .detailsGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .detailItem {
    display: flex;
    align-items: center;
  }
  
  .detailItem svg {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  
  .detailText {
    font-size: 14px;
    color: #555;
  }
  
  .detailValue {
    font-weight: bold;
  }
  
  .descriptionText {
    font-size: 14px;
    color: #555;
    margin-top: 10px;
    line-height: 1.5;
  }
  
  .overviewIcon {
    color: #4caf50;
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    .basicDetailsContainer {
      padding: 15px;
      margin-top: 10px;
    }

    .basicDetailsHeading {
      font-size: 14px;
    }

    .detailsGrid {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      margin-bottom: 10px;
    }

    .detailText {
      font-size: 11px;
    }
  }
