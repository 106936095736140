.container {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 370px;
  margin-top: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.menuButton {
  background: none;
  border: none;
  font-size: 16px;
  color: #888;
  cursor: pointer;
}

.menuButton:hover {
  color: #555;
}

@media (max-width: 768px) {
  .container {
    padding: 10px;
    width: 90%;
    margin-top: 10px;
  }

  .header {
    margin-bottom: 10px;
  }

  .title {
    margin: 0px;
    font-size: 14px;
    padding: 10px 10px 10px 5px;
  }
}