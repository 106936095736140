.cardsContainer {
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 20px;
  padding: 10px;
  width: 100%;
}

.card {
  flex: 1;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px; /* Increased font size */
  font-weight: bold; /* Made the font bold */
  color: #333; /* Optional: Change the color if needed */
  margin-bottom: 10px;
}

.icon {
  color: #4caf50;
  font-size: 20px;
}

.cardContent {
  padding: 10px 0;
  flex: 1;
}

.valueChangeContainer {
  display: flex;
  align-items: baseline;
  gap: 8px;
}

.cardContent h2 {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.increase {
  color: #4caf50;
  font-size: 14px;
}

.decrease {
  color: #f44336;
  font-size: 14px;
}

.cardLink {
  color: #333; /* Changed the link color to a dark shade */
  text-decoration: none;
  font-size: 14px;
  margin-top: 10px;
  display: inline-block;
  align-self: flex-start;
  text-decoration: underline;
}

.cardLink:hover {
  text-decoration: underline;
}

.separator {
  margin: 20px 0;
  border: 0;
  height: 1px;
  background-color: #ddd;
}

@media (max-width: 768px) {
  .cardsContainer {
    gap: 10px;
    padding: 0px;
    overflow-x: auto;
  }

  .valueChangeContainer {
    display: flex;
    align-items: baseline;
    font-size: 12px;
    gap: 8px;
  }

  .cardHeader {
    font-size: 14px;
    margin-bottom: 5px;
    gap: 7px;
    align-items: flex-start;
  }

  .icon {
    font-size: 14px;
  }

  .cardLink {
    font-size: 12px;
  }

  .separator {
    margin: 15px 0;
  }
}