/* app/components/TopBanner.module.css */

.banner {
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 30px;
  background-color:#45a049;
  justify-content: space-between;
  min-height: 60px;
}

.logoContainer {
  display: flex;
  align-items: center;
}

.logo {
  height: 35px;
  margin-right: 20px;
}

.navMenu {
  padding-top: 10px;
  display: flex;
}

.navItem {
  margin-left: 20px;
  position: relative;
}

.navLink {
  text-decoration: none;
  color: white;
  font-size: 16px;
  padding: 8px 12px; /* Add padding for better spacing */
  border-radius: 8px; /* Rounded corners for the menu items */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.navItem:hover .navLink {
  background-color: rgba(255, 255, 255, 0.2); /* Light translucent background on hover */
  color: white; /* Keep the text color white */
}

.navLinkDisabled {
  color: #ccc; /* Disabled link color */
  cursor: default; /* Show default cursor for disabled link */
}

.submenu {
  position: absolute;
  top: 180%; /* Adjust as necessary */
  left: -100px; /* Adjust as necessary */
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  width: 200px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.submenuItem {
  padding: 10px;
  text-align: left;
  border: none;
  background: none;
  width: 100%;
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.submenuItem:hover {
  background-color: #f0f0f0;
}


.upgradeButton {
  background-color: #155724; /* Bright blue background */
  color: white;
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.upgradeButton:hover {
  background-color: #155724; /* Darker blue on hover */
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
}

.icon {
  width: 32px; /* Icon size */
  height: 32px; /* Icon size */
  background-color: #cce6ff; /* Light blue background */
  color: #004080; /* Darker blue for question mark */
  border-radius: 50%; /* Circular shape */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px; /* Font size of the question mark */
  cursor: pointer;
}

.profileIcon {
  width: 32px;
  height: 32px;
  background-color: #cce6ff; /* Light purple background */
  color: #004080;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px; /* Font size for initials */
  margin-left: 12px;
  cursor: pointer;
}

/* Profile dropdown */
.profileDropdown {
  position: absolute;
  right: 10px; /* Adjust position relative to the profile icon */
  top: 50px; /* Adjust to sit below the profile icon */
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  width: 200px;
  z-index: 1000;
}

.profileDropdownHeader {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.profileDropdownHeader strong {
  font-size: 14px;
}

.profileDropdownHeader small {
  display: block;
  color: #888;
}

.profileDropdownItem {
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  text-align: left;
}

.profileDropdownItem:hover {
  background-color: #f0f0f0;
}

/* Adjust the dropdown's alignment */
.profileDropdownItem:last-child {
  border-top: 1px solid #eee;
}


/* Help dropdown */
.helpDropdown {
  position: absolute;
  right: 10px; /* Adjust position relative to the profile icon */
  top: 50px; /* Adjust to sit below the profile icon */
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  width: 200px;
  z-index: 1000;
}

.helpDropdownHeader {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.helpDropdownHeader strong {
  font-size: 14px;
}

.helpDropdownHeader small {
  display: block;
  color: #888;
}

.helpDropdownItem {
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  text-align: left;
}

.helpDropdownItem:hover {
  background-color: #f0f0f0;
}

/* Adjust the dropdown's alignment */
.helpDropdownItem:last-child {
  border-top: 1px solid #eee;
}


@media only screen and (max-width: 600px) {
.banner{
  padding-left: 40px;
  
}

}