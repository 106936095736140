/* src/HomePage.module.css */
.homepageContainer {
  font-family: Arial, sans-serif;
}

.header {
  /* background-image: linear-gradient(#155019, #28a745);  color: white; */
  background: #45a049;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 9px 0px 0px;
  height: 55px;
  position: fixed;
  width: 100%;
  z-index: 10;
}

.logoContainer {
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.logo {
  height: 30px; /* Reduced height */
  margin-right: 10px;
}

.headerRight {
  display: flex;
  align-items: center;
}

.headerLink, .signInLink {
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin-left: 20px;
}

.carousel {
  margin: 20px auto;
  max-width: 800px;
}

.introSection {
  text-align: center;
  margin: 20px;
}

.tagline {
  margin-top: 10px;
  font-size: 1.2em;
  /* background-image: linear-gradient(#45a049, #97BC62FF); */
}

.whyInvestSection {
  background-color: #fff;
  padding: 20px;
  margin: 20px;
  text-align: left;
}

.whyInvestSection h2 {
  text-align: left;
}

.whyInvestSection ul {
  list-style-type: disc;
  margin: 10px 0 0 20px;
}

.featuresSection {
  background-color: #f4f4f4;
  padding: 20px;
  text-align: center;
}

.features {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.feature {
  background-color: white;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 45%;
  max-width: 300px;
}

.ctaSection {
  text-align: center;
  padding: 20px;
  gap: 16px;
  display: grid
}

.ctaSection button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.ctaSection button:hover {
  background-color: #45a049;
}

.faqSection {
  background-color: #fff;
  padding: 20px;
  margin: 20px;
  text-align: left;
}

.faqSection h2 {
  text-align: left;
  color: #4caf50;
}

.faqSection ul {
  list-style: none;
  padding: 0;
}

.faqItem {
  margin: 10px 0;
}

.faqQuestion {
  background: none;
  border: none;
  color: inherit; /* Use the same color as other text */
  cursor: pointer;
  font-size: 1.1em;
  font-weight: bold;
  text-align: left;
  width: 100%;
  padding: 10px 0;
}

.faqAnswer {
  margin: 10px 0;
  padding: 10px;
  background: #8B4513; /* Brown background */
  border-radius: 5px;
}

.footer {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  position: relative;
}

.footerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footerLinks a {
  color: white;
  margin-left: 10px;
  text-decoration: none;
}

.footerLinks a:hover {
  text-decoration: underline;
}

.socialMedia a {
  color: white;
  margin-left: 10px;
  font-size: 1.5rem;
}

.socialMedia a:hover {
  color: #ddd;
}

.signUpButton {
  font-size: large;
}

@media (max-width: 768px) {
  .header {
    padding: 5px 10px;
    font-size: 10px;
  }

  .headerLink, .signInLink {
    margin-left: 10px;
  }

  .logoContainer {
    margin-left: 15px;
  }

  .introSection {
    font-size: 14px;
  }

  .ctaSection {
    padding: 10px;
  }

  .ctaSection P{
    margin-bottom: 10px;
  }

  .ctaSection h2 {
    padding: 10px;
    margin: 0;
    font-size: 23px;
  }

  .ctaSection button {
    padding: 10px 15px;
  }

  .signUpButton {
    font-size: 12px;
  }

  .footer {
    padding: 10px 10px;
    font-size: 12px;
    height: 45px;
  }

  .socialMedia a {
    margin-left: 5px;
    font-size: 15px;
  }

  .footerLinks a {
    margin-left: 4px;
  }

  .footerContent p {
    width: 33%;
    display: flex;
  }

  .footerLinks {
    width: 33%;
  }
}