.container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    /* Overlay css starts here */
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);  
    padding: 20px;
    /* Overlay css ends here */
  }

  /* .container {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    margin: 20px auto;
    font-family: Arial, sans-serif;
    color: #333;

    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  } */
  
  .investProcess {
    flex: 4; /* 80% width */
  }
  
  .assetInvestStats {
    flex: 1; /* 20% width */
  }
  

  /* Overlay styles */
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10; /* Ensure it is above the content */
  }
  
  .overlayText {
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }

  @media only screen and (max-width: 600px) {
    .overlayText {
      font-size: 14px;
    }
  }
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
      gap: 0px;
      padding: 0px;
      box-shadow: none;
    }
  }