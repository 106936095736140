/* app/pages/AssetsliceToken.module.css */

.container {
    padding: 20px;
    text-align: center;
  }

.mainContent{
   margin-top: 3rem;
   display: inline-grid;
    row-gap: 9px;
}
.details{
  color: blue;
}