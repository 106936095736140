.container {
    width: 97%;
    margin: 0 auto; /* Centers the container horizontally */
    background-color: #f4f4f4;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.header {
    background-color: #f9f9f9;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    position: relative;
}

.creditBox {
    background-color: #ffd700;
    width: 120px;
    height: 60px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    color: white;
    position: absolute;
    right: 20px;
    top: 20px;
}

.shareSection {
    margin-top: 20px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
}

.linkBox {
    display: flex;
    margin-bottom: 10px;
}

.linkBox input {
    flex: 1;
    padding: 10px;
    margin-right: 10px;
}

.socialButtons {
    display: flex;
    justify-content: center;
}

.stats {
    display: flex;
    justify-content: space-around;
    background-color: #fff;
    margin-top: 20px;
    padding: 20px;
    border-radius: 8px;
}

.stats div {
    text-align: center;
}

.stats span {
    display: block;
    font-size: 2em;
    color: #007bff;
}

.stats p {
    margin: 0;
    color: #666;
}


.socialButtons button {
    border: none;
    color: white;
    padding: 10px 20px;
    border-radius: 20px; /* Rounded corners */
    margin: 5px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.socialIcons {
    margin-right: 6px;
    margin-top: -2px;
}

.facebookButton {
    background-color: #3b5998; /* Facebook color */
}

.twitterButton {
    background-color: #1da1f2; /* Twitter color */
}

.linkedinButton {
    background-color: #0077b5; /* LinkedIn color */
}

.emailButton {
    background-color: #0072C6; /* Generic email color */
}

.contactButton {
    background-color: #4E4E4E; /* Generic dark color for contact import */
}

.socialButtons button:hover {
    opacity: 0.8;
}

.socialButtons button FontAwesomeIcon {
    margin-right: 10px;
}

@media (max-width: 768px) {
    .container {
        width: 100%;
        margin: 0;
        padding: 15px;
        /* background: none; */
        /* box-shadow: none; */
        border-radius: 10px;
    }

    .header {
        padding: 15px;
        box-shadow: 0 0px 10px rgba(0,0,0,0.1);
        background-color: #fff;
    }

    .header h2{
        font-size: 16px;
        margin-bottom: 10px;
    }

    .header p{
        font-size: 12px;
    }

    .shareSection {
        margin-top: 10px;
        background-color: #fff;
        padding: 15px;
        box-shadow: 0 0px 10px rgba(0,0,0,0.1);
    }

    .shareSection h3{
        display: flex;
        justify-content: center;
        font-size: 16px;
        margin-bottom: 10px;
    }

    .linkBox {
        margin-bottom: 10px;
        height: 25px;
        padding: 0px 0px 0px 2px;
    }

    .linkBox button{
       font-size: 12px;
       padding: 3px;
    }

    .linkBox input {
        font-size: 12px;
    }

    .socialButtons button {
        padding: 8px 8px;
        border-radius: 15px;
        margin: 2px;
        font-size: 10px;
    }

    .stats {
        background-color: #fff;
        box-shadow: 0 0px 10px rgba(0,0,0,0.1);
        margin-top: 10px;
        padding: 15px;
        border-radius: 8px;
    }

    .stats span{
        font-size: 26px;
    }

    .stats p{
        font-size: 12px;
    }
}