.loginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #45a049;
}

.loginBox {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  width: 400px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  text-align: center; /* Center align the content */
}

.logo {
  width: 200px; /* Adjust size as needed */
  margin-bottom: 20px;
}

.inputField {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  text-align: left; /* Align labels to the left */
}

.inputField label {
  margin-bottom: 5px;
  font-weight: bold;
}

.inputField input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.inputField input.invalid {
  border-color: red;
}

.errorMessage {
  color: red;
  margin-top: 5px;
}

.button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #45a049;
}

.linksContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.link {
  color: #4CAF50;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.link:hover {
  color: #388E3C;
}

.error {
  color: red;
  margin-bottom: 10px;
}

/* Password requirements */
.passwordRequirements {
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
}

.requirement {
  display: flex;
  align-items: center;
  color: #555;
  margin-bottom: 5px;
  font-size: 14px;
}

.checkmark {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: rgb(124, 134, 142); /* Grey background for circle */
  color: white; /* Grey color for checkmark */
}

.valid {
  color: white;
  background-color: green; /* Green background for valid */
}

.checkboxContainer {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  font-size: 14px;
}

.checkboxContainer input[type="checkbox"] {
  margin-right: 10px;
  margin-top: 2px;
  color: #45a049;
  background-color: #45a049;
}

.checkboxLabel {
  color: #333;
  display: inline-block;
  margin-top: 0px; /* To align label with checkbox */
  text-align: justify;
}

.checkboxLabel a {
  color: #4CAF50;
  text-decoration: none;
}

.checkboxLabel a:hover {
  text-decoration: underline;
}

.checkboxContainer input[type="checkbox"]:focus + .checkboxLabel {
  outline: 1px solid #4CAF50;
}

.securityNotice {
  background-color: #f0f4f8;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: smaller;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: space-between; */
  width: 100%; /* Make sure it takes the full width */
  text-align: center; /* Align text to the left */
}

.lockIcon {
  font-size: 18px;
  color: #4CAF50; /* Green color for lock icon */
  margin-right: 5px;
}

.secureLink {
  color: #4CAF50;
  font-weight: bold;
  text-decoration: none;
}

.secureLink:hover {
  text-decoration: underline;
}

.loading {
  font-size: 14px;
  color: #007bff;
  margin-bottom: 15px;
}

.success {
  font-size: 14px;
  color: #45a049;
  margin-bottom: 15px;
}

.error {
  color: red;
  font-size: 14px;
  margin-bottom: 15px;
}
