/* src/components/AssetDetailViewer/AssetCarousel/LightboxModal.module.css */
.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Reduced transparency */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContent {
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
    text-align: center;
  }
  
  .modalImage {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
  }
  
  .prevButton, .nextButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
  }
  
  .prevButton {
    left: 10px;
  }
  
  .nextButton {
    right: 10px;
  }
  