/* PrivacyPolicy.module.css */

/* Renamed from .policy-draft-container */
.policyDraftContainer {
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  /* background-color: #f9f9f9; */
  /* border-radius: 8px; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

/* Renamed from .policy-title */
.policyTitle {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

/* Renamed from .policy-date */
.policyDate {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 15px;
}

/* Renamed from .policy-section */
.policySection {
  margin-bottom: 20px;
}

.policySection h2 {
  font-size: 1.5rem;
  color: #444;
  margin-bottom: 10px;
}

.policySection p, .policySection ul {
  margin-bottom: 10px;
}

.policySection ul {
  list-style-type: disc;
  padding-left: 20px;
}

.policySection li {
  margin-bottom: 5px;
}

/* Address styling can remain as is, or rename if you prefer. */
address {
  font-style: normal;
  line-height: 1.6;
  margin-top: 10px;
}

address p {
  margin: 0;
}

.headerContainer {
  display: flex;
  justify-content:center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 60px;
}

.header {
  margin-bottom: 30px;
}

.mainTitle {
  font-size: 3rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.subTitle {
  font-size: 1.25rem;
  color: #666;
}
