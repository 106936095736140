.container {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
  text-align: left; /* Left align the title */
}

.option {
  margin-bottom: 20px;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.label {
  display: block;
  cursor: pointer;
  font-size: 16px;
  color: #333;
}

.checkbox {
  float: right;
  margin-bottom: 50px;
  margin-left: 10px;
}

.authenticationOption strong {
  display: block; /* Make strong text block to move subsequent text to the next line */
  font-weight: bold;
}

.activeLabel {
  color: green;
  font-weight: bold;
  margin-right: 10px;
}

.enableButton {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.disableButton {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.enableButton:hover, .disableButton:hover {
  opacity: 0.8;
}

.twoFaStatus {
  margin-top: 20px;
}


.activeLabel {
  color: green;
  font-weight: bold;
  margin-right: 10px;
}

.enableButton {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.disableButton {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.enableButton:hover, .disableButton:hover {
  opacity: 0.8;
}

.twoFaStatus {
  margin-top: 20px;
}


.container {
  padding: 20px;
}

.title {
  font-size: 24px;
  margin-bottom: 10px;
}

.option {
  margin-bottom: 20px;
}

.label {
  display: flex;
  align-items: center;
}

.authenticationOption {
  flex-grow: 1;
}

.checkbox {
  margin-top: -5px; /* Adjust this value to move the checkbox up */
  margin-left: 10px; /* Optional: Add a little space between text and checkbox */
  transform: scale(1.2); /* Optional: Make the checkbox a little bigger */
}
