.chartContainer {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
}

@media (max-width: 768px) {
    .chartContainer {
        margin: 0;
        padding: 0px;
    }
}
