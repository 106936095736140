.propertyTokenContainer {
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    max-width: 100%;
  }
  
  .propertyTokenTitle {
    font-size: 24px;
    margin-bottom: 15px;
    text-align: left;
  }
  
  .infoText {
    font-size: 16px;
    color: #555;
    margin-bottom: 10px;
  }
  
  .connectedAddressTitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
    text-align: left;
  }
  
  .walletDetails {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .etherscanLink {
    font-size: 16px;
    color: #007bff;
    text-decoration: none;
    margin-right: 8px;
  }
  
  .copyIcon {
    cursor: pointer;
    color: #007bff;
  }
  
  .connectButton {
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .connectButton:hover {
    background-color: #45a049;
  }
  
  .errorMessage {
    color: #ff4d4f;
    font-size: 14px;
    margin-top: 10px;
  }
  
  .changeWalletText {
    font-size: 14px;
    color: #888;
  }
  