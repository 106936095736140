/* src/components/AssetDetailEditor/AssetPage.css */
.assetPageBaseContainer {
  font-family: Arial, sans-serif;
  /* padding: 20px 50px 50px 50px !important; */
  background-color: #f9f9f9;
    margin: 0px 50px 50px 50px;
  }
  
  .assetHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .assetHeader h1 {
    margin: 0;
  }
  
  .assetHeader p {
    margin: 5px 0;
  }
  
  .assetPrice {
    color: green;
    font-size: 24px;
    font-weight: bold;
  }
  
  .assetContent {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .assetGallery {
    flex: 1;
  }
  
  .assetDetails {
    flex: 2;
  }
  
  .similarProperties {
    margin-top: 20px;
  }
  
  .propertyCard {
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px;
    text-align: center;
  }


