/* src/components/PersonalInformation/PersonalInformation.module.css */

.container {
  margin: 0 0 20px 0;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #e0e0e0; /* Added border for sections */
}

.container h2 {
  font-size: 18px;
  margin-bottom: 20px;
  text-align: left;
}

.formGroup {
  margin: 15px;
}

.formGroup label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.formGroup input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding-right: 20px; /* Added right padding */
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr;
  }
}

.saveButton {
  display: block;
  margin-left: auto; /* Aligns the button to the right */
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.saveButton:hover {
  background-color: #45a049;
}

/* Notification Styles */
.success {
  background-color: #d4edda;
  color: #155724;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid #c3e6cb;
}

.error {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid #f5c6cb;
}
