.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
    
  .modalContainer {
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
    max-width: 90%;
    max-height: 90%;
    width: 600px;
    height: 400px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.2);
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
    
  .closeButton {
    background: none;
    border: none;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
    cursor: pointer;
    line-height: 20px;
  }
  
  .title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #fff;
  }
  
  .description {
    color: #fff;
    margin-bottom: 20px;
    font-size: 0.9rem;
  }
  
  .form {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .emailInput {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 15px;
    font-size: 1rem;
    width: 100%;
  }

  .errorMessage {
    color: #ff0000;
    font-size: 0.8rem;
    margin-bottom: 10px;
  }
  
  .submitButton {
    background-color: #45a049;
    border: none;
    border-radius: 4px;
    padding: 10px;
    color: #ffffff;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .noThanksButton {
    padding-top: 20px;
    background: none;
    border: none;
    color: #888;
    text-decoration: underline;
    cursor: pointer;
    font-size: 0.9rem;
  }
  
  @media (max-width: 768px) {
    .modalContainer {
      width: 90%;
      padding: 15px;
    }
  
    .title {
      font-size: 1.2rem;
    }
  
    .description {
      font-size: 0.8rem;
      margin-bottom: 15px;
    }
  
    .emailInput {
      font-size: 0.9rem;
    }
  
    .submitButton {
      font-size: 0.9rem;
    }
  
    .noThanksButton {
      font-size: 0.8rem;
    }
  }
  